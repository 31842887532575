import { template as template_b04a1cd7772a4730b7a82a6d53af5735 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_b04a1cd7772a4730b7a82a6d53af5735(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
