import { template as template_004d84c683b14b9cb5ff4ba6a4b57326 } from "@ember/template-compiler";
const FKText = template_004d84c683b14b9cb5ff4ba6a4b57326(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
