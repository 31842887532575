import { template as template_25a6be2430b44151bb645c8568e4b400 } from "@ember/template-compiler";
const FKLabel = template_25a6be2430b44151bb645c8568e4b400(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
