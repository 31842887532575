import { template as template_07bb71c7d2b649dba7e064474f8cccf0 } from "@ember/template-compiler";
const WelcomeHeader = template_07bb71c7d2b649dba7e064474f8cccf0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
